import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Contributor from "../components/contributor"
import {mapOrder} from "../functions/MapOrder"


const ContributorBlock = ({title, contributors, order}) => {
    contributors = order && order.length > 0 ? mapOrder(contributors, order) : contributors
    return (
        <>
        { title !== "" && <h1 className="">{title}</h1> }
        {contributors.map((contributorEdge) => (
            <Contributor contributor={contributorEdge.node} key={contributorEdge.node.wordpress_id}/>
        ))}
        </>
    )
}
  
ContributorBlock.propTypes = {
    title: PropTypes.string,
    contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
    order: PropTypes.arrayOf(PropTypes.number),
}

ContributorBlock.defaultProps = {
    title: "",
    order: [],
}

export const query = graphql`
  fragment ContributorBlockFragment on WpTemplate_About {
    currentStaffMembers {
      scpContributors {
        ... on WpStaff {
          databaseId
          ...ContributorFragment
        }
      }
    }
  }
`

export default ContributorBlock