import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import StaffMemberBlock from "../components/staff-member-block"
import ContributorBlock from "../components/contributor-block"


const AboutPage = ({data, pageContext}) => {
  var staff = data.wpPage.template.currentStaffMembers.scpCurrentStaff
  var contributors = data.wpPage.template.currentStaffMembers.scpContributors
  return (
    <Layout>
      <div className="mb-8">
      <Seo title={data.wpPage.title} />
      <h1>{ data.wpPage.title }</h1>
      <div className="wordpressContent pl-4 pr-4">
        <p className="text-default " dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
      </div>
      { staff && staff.length > 0 &&
        <>
          <hr></hr>
          <StaffMemberBlock title="Staff Members" staff={staff} order={pageContext.staff}/>
        </>
      }
      { contributors && contributors.length > 0 &&
        <>
          <hr></hr>
          <ContributorBlock title="Contributors" contributors={data.contributors.edges} order={pageContext.contributors}/>
        </>
      }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery($id : String) {
    wpPage(id: {eq: $id}) {
      ...DefaultPageFragment
      template {
        ... on WpTemplate_About {
          ...StaffBlockFragment
          ...ContributorBlockFragment
        }
      }
    }
  }
`

export default AboutPage