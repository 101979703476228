import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const StaffMember = ({member, index}) => {
  var baseClass = "flex w-full h-48 sm:pl-12 sm:pr-12 md:pl-24 md:pr-24 lg:w-1/2 lg:pl-0 lg:pr-0 lg:odd:pr-8 lg:even:pl-8";
  var marginTop = baseClass;
  if(index + 1 === 2)
    marginTop += " mt-8 lg:mt-0";
  else if(index + 1 > 2)
    marginTop += " mt-8";

  return (
    <div className={marginTop}>
      {member.featuredImage !== null &&
        <div className="flex hidden md:block lg:hidden xl:block">
          <Img 
            resolutions={member.featuredImage.node.localFile.childImageSharp.fixed} 
            key={member.featuredImage.node.localFile.childImageSharp.fixed.src}
          />
        </div>
      }
      <div className="flex-grow">
        <p style={{height: `17%`,}} className="text-default"><span className="font-bold">{member.title + ", "}</span>{member.memberDetails.scpStaffRole}</p>
        <div className="bg-scplightgrey p-3 pt-2 pb-2 overflow-hidden"style={{height: `83%`,}}>
          <p className="text-scpdarkgrey text-sm font-body">{member.memberDetails.scpStaffBio}</p>
        </div>
      </div>
    </div>
  )
}
  
StaffMember.propTypes = {
    member: PropTypes.object.isRequired,
    index: PropTypes.number
}

export const query = graphql`
  fragment StaffMemberFragment on WpStaff {
    title
    featuredImage {
      node {
        localFile {
          childImageSharp {
            fixed(width: 192, height: 192) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
    memberDetails {
      scpStaffBio
      scpStaffEmail
      scpStaffPhonenumber
      scpStaffRole
    }
  }
`
export default StaffMember