import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

const StaffMember = ({contributor}) => {
    return (
      <div className="text-center md:text-left md:pl-16">
        <p className="text-default"><span className="font-bold">{contributor.title + ", "}</span>{contributor.memberDetails.scpStaffRole}</p>
      </div>
    )
}
  
StaffMember.propTypes = {
    contributor: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment ContributorFragment on WpStaff {
    title
    memberDetails {
      scpStaffRole
    }
  }
`
export default StaffMember