import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import StaffMember from "../components/staff-member"
import {mapOrder} from "../functions/MapOrder"


const StaffMemberBlock = ({title, staff, order}) => {
    staff = order && order.length > 0 ? mapOrder(staff, order) : staff
    return (
      <>
      { title !== "" && <h1 className="">{title}</h1> }
      <div className="px-2">
        <div className="flex -mx-2 flex-wrap">
          {staff.map((staffEdge, i) => (
              <StaffMember member={staffEdge} index={i} key={staffEdge.databaseId}/>
          ))}
        </div>
      </div>
      </>
    )
}
  
StaffMemberBlock.propTypes = {
  title: PropTypes.string,
  staff: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.arrayOf(PropTypes.number),
}

StaffMemberBlock.defaultProps = {
  title: "",
  order: [],
}

export const query = graphql`
  fragment StaffBlockFragment on WpTemplate_About {
    currentStaffMembers {
      scpCurrentStaff {
        ... on WpStaff {
          databaseId
          ...StaffMemberFragment
        }
      }
    }
  }
`

export default StaffMemberBlock